import React, { useState, useContext } from 'react'
import DisplayItem from '../../displayItem/displayItem'
import styles from '../../uploadSummary/uploadSummary.module.scss'
import Buttons from '../../buttons/buttons'
import { Context } from '../../context/provider'
import Loader from 'react-loader-spinner'
import { usePostError } from '../../../utilities/usePostError'
import axios from 'axios'
import { useSiteConfig } from '../../layout/getSiteConfig'

export default function BoatSummary() {
  const context = useContext(Context)
  const storage = typeof window !== 'undefined' ? window.sessionStorage : {}
  const [loader, setLoader] = useState(false)
  const handleError = usePostError()
  const { boatRenewalInfoApi } = useSiteConfig()

  const { name, email, phone, boatNumber, boatYear, Fee, rollNumber, transactionId } = storage;

  function formatNum(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  const handleNext = async () => {
    setLoader(true)
    window.sessionStorage.setItem('mcn-form-path', 'renew-boat')

    const body = {
      key: process.env.GATSBY_SECRET,
      storage: {
        name,
        rollNumber,
        email,
        transactionId,
        phone,
        boatNumber,
        boatYear,
        Fee
      }
    }

    try {
      await axios.post(boatRenewalInfoApi, { ...body })

      setLoader(false)
      context.setCurrent(2)

    } catch(e) {
      setLoader(false)
      handleError(e)
    }
  }
  
  const handleBack = () => {
    context.setCurrent(0)
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Summary</h2>

        <DisplayItem {...{
          value: boatNumber,
          label: 'Boat HIN / Serial #',
        }} />
        <DisplayItem {...{
          value: boatYear,
          label: 'Boat Year',
        }} />
        <DisplayItem {...{
          value: name,
          label: 'Name',
        }} />
        <DisplayItem {...{
          value: email,
          label: 'Email',
        }} />
        <DisplayItem {...{
          value: phone,
          label: 'Phone',
        }} />
        <DisplayItem {...{
          value: rollNumber,
          label: 'Citizen roll number',
        }} />

        <hr className={styles.line}></hr>

        <div className={styles.fees}>
          <DisplayItem {...{
            value: Fee,
            display: (val) => `$${formatNum(val)}`,
            label: 'Fee',
            total: true,
            showCheck: false,
          }} />
        </div>

        <Buttons {...{
          handleBack,
          handleNext,
          backLabel: 'BACK',
          nextLabel: 'PAYMENT'
        }}>
          {loader && <div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}