import React, { useState, useContext, useEffect } from 'react'
import styles from '../../uploadDocuments/uploadDocuments.module.scss'
import Buttons from '../../buttons/buttons'
import ErrorMessage from '../../errorMessage/errorMessage'
import { useSiteConfig } from '../../layout/getSiteConfig'
import { usePostError } from '../../../utilities/usePostError'
import axios from 'axios'
import { Context } from '../../context/provider'
import { getStorageItem } from '../../../utilities/getStorageItem'
import Loader from 'react-loader-spinner'
import Input from '../../input/input'
import { getTransactionId } from '../../../utilities/getTransactionId'
import CheckInput from '../../checkInput/checkInput'
import { validateEmail } from '../../../utilities/validateEmail'

export default function BoatPersonalInfo() {
  const [boatNumber, setBoatNumber] = useState(getStorageItem('boatNumber'))
  const [boatYear, setBoatYear] = useState(getStorageItem('boatYear'))
  const [name, setName] = useState(getStorageItem('name'))
  const [email, setEmail] = useState(getStorageItem('email'))
  const [phone, setPhone] = useState(getStorageItem('phone'))
  const [rollNumber, setRollNumber] = useState(getStorageItem('rollNumber'))
  const [validEmail, setValidEmail] = useState(false)
  const [validYear, setValidYear] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState(getStorageItem('email'))
  const [errorMessage, setErrorMessage] = useState('')
  const context = useContext(Context)
  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)

  const isMatch = email === confirmEmail
  const handleError = usePostError()

  const calculateFee = () => {
    if (+boatYear >= 2019) {
      return 75
    }
    if (+boatYear >= 2014) {
      return 55
    }
    if (+boatYear >= 2009) {
      return 30
    }
    return 15
  }

  const handleSubmit = async () => {
    setSubmit(true)
    const transactionId = getTransactionId()

    const isEmail = validateEmail(email, setValidEmail)
    const isValidYear = boatYear.length === 4

    const requiredFields = [isEmail, isMatch, rollNumber, name, phone, boatNumber, boatYear, isValidYear]

    if (!requiredFields.every(x => x)) {
      return
    }

    setErrorMessage('')
    setLoader(true)

    window.sessionStorage.setItem('mcn-form-filled', true)

    try {
      
      const items = {
        boatNumber,
        boatYear,
        transactionId,
        Fee: calculateFee(),
        email,
        name,
        rollNumber,
        phone,
      }

      Object.entries(items).forEach(([key, value]) => {
        window.sessionStorage.setItem(key, value)
      })

      setSubmit(false)
      setLoader(false)
      context.setCurrent(1)

    } catch(e) {
      handleError(e)
      setLoader(false)
    }
  }

  const handleBack = () => {
    context.setCurrent('')
    setErrorMessage('')
  }

  const handleChange = (e) => {
    const value = e.target.value
    if (e.target.name === 'name') {
      setName(value)
    }
    if (e.target.name === 'email') {
      setEmail(value)
      if (submit) {
        validateEmail(value, setValidEmail)
      }
    }
    if (e.target.name === 'confirmEmail') {
      setConfirmEmail(value)
    }
    if (e.target.name === 'rollNumber') {
      setRollNumber(value)
    }
    if (e.target.name === 'phone') {
      setPhone(value)
    }
    if (e.target.name === 'boatNumber') {
      setBoatNumber(value)
    }
    if (e.target.name === 'boatYear') {
      setBoatYear(value)
      if (submit) {
        setValidYear(value.length === 4)
      }
    }
  }

  useEffect(() => {
    window.sessionStorage.setItem('mcn-form-filled', false)
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Boat / Personal Information</h2>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Boat HIN or serial #',
            name: 'boatNumber',
            value: boatNumber,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !boatNumber],
          }} />
          
           <Input {...{
            label: 'Boat year',
            name: 'boatYear',
            value: boatYear,
            xStyle: 'right',
            handleChange,
            invalid: [submit, !boatYear || !validYear]
          }}>
            <CheckInput {...{
              text: 'Please enter a valid year (YYYY)',
              values: [submit, (!boatYear || !validYear)],
            }} />
          </Input>
        </div>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Name',
            name: 'name',
            value: name,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !name],
          }} />
          
           <Input {...{
            label: 'Citizen roll number',
            name: 'rollNumber',
            value: rollNumber,
            xStyle: 'right',
            handleChange,
            invalid: [submit, !rollNumber]
          }} />
        </div>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Email',
            name: 'email',
            value: email,
            xStyle: 'left',
            handleChange,
            invalid: [submit, (!email || !validEmail)],
          }}>
            <CheckInput {...{
              text: 'Please enter a valid email address',
              values: [submit, (!email || !validEmail)],
            }} />
          </Input>
          <Input {...{
            label: 'Confirm email',
            name: 'confirmEmail',
            value: confirmEmail,
            xStyle: 'right',
            handleChange,
            invalid: [submit, (!isMatch || !confirmEmail)],
          }}>
            <CheckInput {...{
              text: 'The email address entered does not match',
              values: [submit, confirmEmail, !isMatch],
            }} />
          </Input>
        </div>
        <div className={styles.inputs}>
          <Input {...{
            label: 'Phone',
            name: 'phone',
            value: phone,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !phone],
          }} />
        </div>
     
        <ErrorMessage message={errorMessage} />

        <Buttons {...{
          handleBack,
          handleNext: handleSubmit,
          backLabel: 'BACK',
          nextLabel: 'NEXT'
        }}>
          {loader && <div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}