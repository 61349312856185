import React, { useState, useEffect } from 'react'
import Marker from '../marker/marker'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from './boatRenewalHome/boatRenewalHome'
import { Context } from '../context/provider'
import { getStorageItem } from '../../utilities/getStorageItem'
import BoatPersonalInfo from './boatPersonalInfo/boatPersonalInfo'
import BoatSummary from './boatSummary/boatSummary'

export default function BoatRenewal() {
  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 1 : '');
  
  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Boat/Personal information',
    'Summary',
    'Payment'
  ]

  useEffect(() => {
    if (current === 2) {
      window.location.href = '/payment'
    }
  }, [current])

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? 'Boat Renewal' : ''} />
      {parseInt(current) >= 0 && current <= 5 && <Marker items={markerItems} />}
      {current === '' && <Home />}
      {current === 0 && <BoatPersonalInfo />}
      {current === 1 && <BoatSummary />}
      <Footer />
    </Context.Provider>
  )
}
