import React from "react"
import Layout from "../components/layout/layout"
import BoatRenewal from "../components/boatRenewal/boatRenewal";

export default function RenewBoat() {
  return (
    <Layout>
      <BoatRenewal />
    </Layout>
  );
}
